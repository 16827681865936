import React, { useState, useEffect } from 'react';
import { ArrowRight, Lock, Crown, Target, Rocket, Star } from 'lucide-react';
import GradientHeader from '../GradientHeader';
import { useSpots } from '../../components/SpotsContext';

const CreditCardIcon = () => (
  <div className="relative inline-flex">
    <svg 
      viewBox="0 0 24 24" 
      width="100%" 
      height="100%" 
      className="w-3 h-3 xs:w-4 xs:h-4 sm:w-5 sm:h-5"
    >
      <rect x="2" y="5" width="20" height="14" rx="2" className="fill-purple-400"/>
      <rect x="2" y="9" width="20" height="3" className="fill-yellow-400"/>
      <line 
        x1="3" 
        y1="3" 
        x2="21" 
        y2="21" 
        stroke="currentColor" 
        strokeWidth="2.5"
        strokeLinecap="round"
        className="stroke-red-500"
      />
    </svg>
  </div>
);

const HeroSection = ({ onRequestAccess }) => {
  const { spotsLeft } = useSpots();
  const [isMobile, setIsMobile] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100);

  useEffect(() => {
    const checkZoom = () => {
      const zoom = window.devicePixelRatio * 100;
      setIsMobile(window.innerWidth < 768);
      setZoomLevel(zoom);
    };
    
    checkZoom();
    window.addEventListener('resize', checkZoom);
    return () => window.removeEventListener('resize', checkZoom);
  }, []);

  // Adjust scaling based on zoom level
  const getResponsiveClasses = () => {
  if (zoomLevel <= 250) return {
    container: 'pt-12 sm:pt-24 md:pt-36 pb-8 sm:pb-16 md:pb-24',
    headline: 'text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl',
    subheadline: 'text-sm xs:text-base sm:text-xl md:text-2xl',
    subheadlineMaxWidth: 'max-w-[95%]', // Default max-width
    ctaButton: 'text-sm xs:text-base sm:text-lg md:text-xl px-6 sm:px-8 md:px-10 py-3 sm:py-4 md:py-5',
    spotText: 'text-xs xs:text-sm sm:text-base'
  };

  // For higher zoom levels, reduce max-width to make text wrap more
  return {
    container: 'pt-6 sm:pt-12 md:pt-18 pb-4 sm:pb-8 md:pb-12',
    headline: 'text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-5xl',
    subheadline: 'text-xs xs:text-sm sm:text-base md:text-lg',
    subheadlineMaxWidth: 'max-w-[70%]', // Reduced max-width for high zoom
    ctaButton: 'text-xs xs:text-sm sm:text-base md:text-lg px-4 sm:px-6 md:px-8 py-2 sm:py-3 md:py-4',
    spotText: 'text-[10px] xs:text-xs sm:text-sm'
  };
};

  const responsiveClasses = getResponsiveClasses();

  return (
    <>
      <div className="bg-gradient-to-br from-blue-900 via-black to-purple-900 relative">
        <div className={`
          max-w-5xl mx-auto 
          px-4 
          ${responsiveClasses.container}
          relative z-10
        `}>
          <div className="text-center">
            <div className="hidden sm:inline-block w-[98%] sm:w-auto px-1 py-2 sm:px-6 sm:py-3 bg-purple-500/20 rounded-full mb-4 sm:mb-10 mx-auto">
              <p className={`
                whitespace-nowrap 
                relative z-10
                ${responsiveClasses.subheadline}
              `}>
                The AI Copywriter That Makes ChatGPT Look Like A Drunk Toddler With A Keyboard
              </p>
            </div>
          
            <h1 className={`
              tracking-wider 
              font-bold 
              mb-2 sm:mb-4 md:mb-6 
              px-2 mx-auto 
              max-w-[95%] sm:max-w-full 
              relative z-10 
              flex flex-col items-center gap-2
              ${responsiveClasses.headline}
            `}>
              <div className="pb-2">
                <GradientHeader>
                  If A Billion-Dollar Marketing Agency And An AI Had A Baby... It'd Be This Bad Boy
                </GradientHeader>
              </div>
              <span className="text-white block">🤖</span>
            </h1>
            
            <p className={`
  font-semibold text-blue-200 
  ${responsiveClasses.subheadlineMaxWidth} mx-auto 
  mb-8 sm:mb-14 md:mb-20 
  px-2 relative z-10 
  leading-snug
  ${responsiveClasses.subheadline}
`}>
  This little genius was trained on <span className="text-blue-200">$4.8B+ in successful campaigns</span> and guarantees a <span className="text-blue-200">40% conversion boost</span>... or we'll eat our hat (and refund your money) 🎩
</p>

            <div className="inline-flex items-center justify-center gap-1.5 xs:gap-2 sm:gap-2.5 mb-3 sm:mb-4">
              <div className="flex items-center gap-0.5 xs:gap-1">
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className={`w-2.5 h-2.5 xs:w-3 xs:h-3 sm:w-4 sm:h-4 ${
                      i < 5 ? 'fill-yellow-400 text-yellow-400' : 'fill-yellow-400/30 text-yellow-400/30'
                    }`}
                  />
                ))}
              </div>
              <span className="text-yellow-400 font-medium text-xs xs:text-sm sm:text-base">4.8</span>
              <span className="text-blue-200 text-xs xs:text-sm sm:text-base">rated by</span>
              <span className="text-blue-400 font-medium text-xs xs:text-sm sm:text-base">600+</span>
              <span className="text-blue-200 text-xs xs:text-sm sm:text-base">users</span>
            </div>

            <button 
              onClick={onRequestAccess}
              style={{
                background: 'linear-gradient(to bottom right, #EE121A, #F54A4C)'
              }}
              className={`
                rounded-xl 
                font-bold 
                hover:opacity-90 transition-all 
                flex items-center gap-2 
                mx-auto mb-4 
                relative z-20
                shadow-[0_0_30px_rgba(238,18,26,0.3)] 
                hover:shadow-[0_0_40px_rgba(238,18,26,0.4)]
                scale-100 hover:scale-105 
                transform transition-all duration-200
                ${responsiveClasses.ctaButton}
              `}
            >
              REQUEST YOUR FREE ACCESS NOW <Lock className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" />
            </button>
            
            <p className={`
              flex justify-center items-center flex-nowrap 
              text-blue-200 
              px-2 sm:px-4 
              relative z-10
              ${responsiveClasses.spotText}
            `}>
              <span className="text-yellow-400 mr-0.5 xs:mr-1">⚡️</span>
              <span>December Offer: Only {isMobile ? '34' : spotsLeft} spots left</span>
              <span className="mx-1 xs:mx-2">•</span>
              <span className="flex items-center gap-1">
                <CreditCardIcon />
                <span className="whitespace-nowrap">No credit card required</span>
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Rest of the component remains the same as in the original code */}
      <div className="py-4 sm:py-6 md:py-8 bg-black/50 border-y border-purple-500/20">
        <div className="max-w-6xl mx-auto px-4">
          <div className="hidden sm:flex justify-center items-center gap-10 text-gray-400 text-xl">
            <div className="flex items-center gap-2.5 whitespace-nowrap">
              <Crown className="w-6 h-6" />
              <span>$4.8B+ Ad Spend Analyzed</span>
            </div>
            <div className="flex items-center gap-2.5 whitespace-nowrap">
              <Target className="w-6 h-6" />
              <span>490K+ Ads Analyzed</span>
            </div>
            <div className="flex items-center gap-2.5 whitespace-nowrap">
              <Rocket className="w-6 h-6" />
              <span>40% Conversion Boost Guarantee</span>
            </div>
          </div>

          <div className="sm:hidden relative overflow-hidden">
            <div className="flex animate-carousel">
              {[...Array(6)].map((_, index) => (
                <div key={index} className="flex shrink-0 items-center gap-2 text-gray-400 px-8">
                  {index % 3 === 0 && (
                    <>
                      <Crown className="w-4 h-4 shrink-0" />
                      <span className="text-sm whitespace-nowrap">$4.8B+ Ad Spend Analyzed</span>
                    </>
                  )}
                  {index % 3 === 1 && (
                    <>
                      <Target className="w-4 h-4 shrink-0" />
                      <span className="text-sm whitespace-nowrap">1.2M+ High-Converting Ads</span>
                    </>
                  )}
                  {index % 3 === 2 && (
                    <>
                      <Rocket className="w-4 h-4 shrink-0" />
                      <span className="text-sm whitespace-nowrap">40% Conversion Boost Guarantee</span>
                    </>
                  )}
                </div>
              ))}
            </div>
            
            <div className="absolute inset-y-0 left-0 w-4 bg-gradient-to-r from-black/50 to-transparent pointer-events-none" />
            <div className="absolute inset-y-0 right-0 w-4 bg-gradient-to-l from-black/50 to-transparent pointer-events-none" />
          </div>
        </div>

        <style jsx>{`
          .animate-carousel {
            animation: carousel 20s linear infinite;
          }

          @keyframes carousel {
            from {
              transform: translateX(0%);
            }
            to {
              transform: translateX(-50%);
            }
          }

          .animate-carousel {
            width: fit-content;
            display: flex;
          }

          .animate-carousel:hover {
            animation-play-state: paused;
          }
        `}</style>
      </div>
    </>
  );
};

export default HeroSection;